<template>
    <div>
        <el-row>
            <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleAdd">
               <!--替換語系
                新建帳號-->
                 {{$t('CreateAccount')}}
            </el-button>

            <!--篩選區塊-->
            <!--<el-divider content-position="right"> {{$t('FilterConditions')}} </el-divider>-->
            <div style="float: right;">
                <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
                    <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
                </el-select>
                <el-select v-model="listQuery.enabled" v-bind:placeholder="$t('EnabledStatus')" size="small" class="filter-item" >
                    <el-option v-bind:label="$t('Enable')" :value="true" />
                    <el-option v-bind:label="$t('Disable')" :value="false" />
                </el-select>
                <el-input size="small" v-model="listQuery.name" v-bind:placeholder="$t('Query')+$t('Account')+$t('Name')" class="filter-item" clearable style="width: 180px;"/>
            </div>
        </el-row>
        <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
            <span >產製時間: {{ reportDate }} </span>
        </el-row>
        <!-- table -->
        <el-table v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{ padding: '0', height: '40px' }"  @sort-change="tableSortChange">
            <el-table-column align="center" prop="account" :label="$t('Account')" sortable >
                <template slot-scope="scope">
                    <el-tooltip v-if="scope.row.account != null" class="item" effect="dark" :content="scope.row.account" placement="top-start">
                    <span v-if="scope.row.account != null">{{hideEmail(scope.row.account)}}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="name" :label="$t('Name')" sortable />
            <el-table-column align="center" prop="service" :label="$t('Operator')" v-if="serviceList.length>0" sortable="custom" >
                <template slot-scope="scope">
                    {{ scope.row.serviceInfo.name }}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="roleInfo.roleNames" :label="$t('RolePermissions')">
                <template slot-scope="scope">
                    <el-tag v-for="(role) in scope.row.roleInfo.roleData" :key="role.id">
                        {{ role.name }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="enabled" :label="$t('EnabledStatus')" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.enabled" type="success">{{$t('Enabled')}}</el-tag>
                    <el-tag v-else type="danger">{{$t('NotEnabled')}}</el-tag>
                </template>
            </el-table-column>
            <!--
            <el-table-column align="center" prop="loggedIn" :label="$t('Login')+$t('Date')" sortable :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column align="center" prop="updatedOn" :label="$t('Update')+$t('Date')" sortable :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <div :title="scope.row.updatedOn">{{ (scope.row.updatedOn !== undefined && scope.row.updatedOn != null && scope.row.updatedOn.length > 0 )?parseDateTime(scope.row.updatedOn):parseDateTime(scope.row.createdOn) }}</div>
                </template>
            </el-table-column>
            -->
            <el-table-column align="center" prop="createdOn" :label="$t('Create')+$t('Date')" :show-overflow-tooltip="true" sortable="custom">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" placement="bottom-end">
                        <div slot="content">{{ $t('Update')+$t('Date') }}: {{ scope.row.updatedOn }}<br/>{{ $t('Login')+$t('Date') }}: {{ scope.row.loggedIn }}</div>
                        <span :title="scope.row.createdOn">{{ parseDateTime(scope.row.createdOn) }}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            
            <el-table-column align="center" :label="$t('Operate')" width="200px">
                <template slot-scope="scope">
                    <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showSetting(scope.row)" />
                    <el-button type="warning" :title="$t('Role')" size="mini" icon="el-icon-user-solid" @click="showRole(scope.row)" />
                    <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete"  @click="handleDelete(scope.$index, scope.row)" />
                </template>
            </el-table-column>

        </el-table>
        <!--分頁組件-->
        <pagination v-show="total > 0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size"
            @pagination="getList" />

        <AccountSetting :dialogVisible="dialogSettingVisible" :accountData="accountData" @close="dialogSettingOnClose()" />
        <AccountRoleSetting :dialogVisible="dialogRoleVisible" :accountData="accountData" @close="dialogRoleOnClose()" />
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import AccountSetting from './AccountSetting.vue'
import AccountRoleSetting from './AccountRoleSetting.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import {apiUrl} from "@/utils/main";

export default {
    name: 'Account',
    components: {
        Pagination,
        AccountSetting,
        AccountRoleSetting
    },
    mixins: [mixins],
    data() {
        return {
            dialogWidth: 0,
            total: 0,
            list: [],
            listLoading: false,
            listQuery: {
                type: 'general',
                enabled: true,
                name: undefined,
                serviceId: undefined,
                page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                size: 15
            },
            serviceList: [],
            paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
            dialogSettingVisible: false,
            dialogRoleVisible: false,
            accountData: undefined, // 帳號資訊
            reportDate: this.formatCurrentDateTime()
        };
    },
    watch: {
        paginationSelectedPage: {
            handler: function (newValue) {
                this.listQuery.page = newValue - 1
            }
        },
        'listQuery.enabled': function () {
            this.listQuery.page = 0
            this.resetPage(this.getList)
        },
        'listQuery.name': function () {
            this.listQuery.page = 0
            this.resetPage(this.getList)
        },
        'listQuery.serviceId': function () {
            this.listQuery.page = 0
            this.resetPage(this.getList)
        }
    },
    created() {
        console.log('@account currentAccount=', this.$store.state.currentAccount)
        this.dialogWidth = this.setDialogWidth()
        // await this.$store.dispatch("getUnitTable")
        this.getServiceOptions()
        this.resetPage(this.getList)
        //refreshToken();

    },
    beforeDestroy(){
      console.log("call beforeDestroy");
      //stopRefreshToken();
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.dialogWidth = this.setDialogWidth()
            })()
        }
    },
    methods: {
        getList() {
            this.listLoading = true
            this.axios.get('/api/v1/account', { params: this.listQuery }).then(res => {
                this.list = res.data.content
                this.total = res.data.totalElements
                this.listLoading = false
                
                this.reportDate = this.formatCurrentDateTime()
            })
        },
        getServiceOptions() {
            this.listLoading = true
            this.axios.get('/api/v1/common/service/enabled').then(res => {
                this.serviceList = res.data
            })
        },
        handleAdd() {
            this.dialogSettingVisible = true
            this.accountData = undefined
        },
        handleDelete(index, row) {
            /*替換語系
            this.$confirm('提示', {
                message: '確定刪除帳號 - ' + row.account + ' (' + row.name + ') ?',
                confirmButtonText: '確認',
                cancelButtonText: '取消', */
            this.$confirm(this.$t('Hint'), {
                message: this.$t('ConfirmDeleteAccount') + '-' + row.account + ' (' + row.name + ') ?',
                confirmButtonText: this.$t('Confirm'),
                cancelButtonText:  this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                this.delAccount(index, row.id)
            }).catch(() => {
                console.log('cancel delete account')
            })
        },
        async delAccount(index, id) {
            this.axios.delete('/api/v1/account/' + id).then(() => {
                this.$delete(this.list, index);
                this.$message({
                    showClose: true,
                    //message: '刪除成功',//替換語系
                    message: this.$t('Delete') + this.$t('Success'),
                    type: 'success'
                })
            }).catch(err => {
                console.log('err:' + err)
                this.$message({
                    //message: '刪除失敗',//替換語系
                    message: this.$t('Delete') + this.$t('Fail'),
                    type: 'error'
                })
            })
        },
        showSetting(row) {
            console.log(row)
            this.dialogSettingVisible = true
            this.accountData = row
        },
        showRole(row) {
            console.log(row)
            this.dialogRoleVisible = true
            this.accountData = row
        },
        dialogSettingOnClose() {
            this.dialogSettingVisible = false
            this.accountData = undefined
            this.getList()
        },
        dialogRoleOnClose() {
            this.dialogRoleVisible = false
            this.accountData = undefined
            this.getList()
        }
    }
}
</script>
