<template>
    <!--替換語系
    <el-dialog title="角色權限設定" :visible="dialogVisible" @close="dialogDataOnClose()">-->
    <el-dialog :title="$t('RoleSettings')" :visible="dialogVisible" @close="dialogDataOnClose()">
        <el-form ref="dataForm" :model="updateQuery" size="small">
            <el-row :gutter="20">
                <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" :push="4" style="text-align: left;">
                    <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate"
                        @change="handleCheckAllChange">
                        <!--替換語系
                            全選-->
                        {{$t('All')}}
                        </el-checkbox>
                    <hr />
                    <el-checkbox-group v-model="checkedRoles" @change="handleCheckedRolesChange">
                        <el-checkbox v-for="role in roles" :key="role.id" :label="role.id">{{
                            role.name
                        }}</el-checkbox>
                    </el-checkbox-group>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">&nbsp;</el-col>
            </el-row>
        </el-form>

        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary"
            @click="updateData()">
            <!--替換語系
            儲存-->
            {{$t('Save')}}
            </el-button>
    </el-dialog>
</template>
<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'AccountRoleSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        accountData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            dialogWidth: 0,
            total: 0,
            list: [],
            listLoading: false,
            accountId: undefined,
            isModify: false,
            updateQuery: {
                id: undefined,
                roles: []
            },
            checkAll: false,
            isIndeterminate: false,
            checkedRoles: [],
            roles: []
        };
    },
    watch: {
        accountData(val) {
            if (val) {
                this.isModify = true

                this.accountId = val.id
                this.updateQuery.id = val.id
                this.updateQuery.roles = val.roleInfo.roleData
                val.roleInfo.roleData.forEach((role) => {
                   this.checkedRoles.push(role.id)
                });
                //this.checkedRoles = val.roleInfo.roleNames
                this.handleIsIndeterminate()
                console.log(this.accountId)
            }
        }
    },
    async created() {
        //因應後端API Url match管控，調整api url，不影響功能
        this.axios.get('/api/v1/role/roleaccount').then((data) => {
            //console.log(data)
            this.roles = data.data
        }).catch(err => {
            console.log('err:' + err)
        });
    },
    methods: {
        dialogDataOnClose() {
            this.checkedRoles = []
            this.$emit('close') // 觸發父層 close
        },
        updateData() {
            console.log(this.accountId)
            console.log(this.checkedRoles)

            this.axios.put('/api/v1/account/'+this.accountId+'/role', this.checkedRoles).then(() => {
                this.$message({
                    showClose: true,
                  //message: '修改成功',//替換語系
                    message: this.$t('SuccessEdited'),
                    type: 'success'
                })
                this.dialogDataOnClose()
            }).catch(err => {
                console.log('err:' + err)
                this.$message({
                  //message: '修改失敗',//替換語系
                    message: this.$t('FailEdited'),
                    type: 'error'
                })
            })

        },
        handleCheckAllChange(val) {
            // val is true or false
            //console.log(this.roles)
            const roleIds = []
            this.roles.forEach((role) => roleIds.push(role.id))
            //console.log(roleIds)
            this.checkedRoles = val ? roleIds : []
            this.isIndeterminate = false
        },
        handleCheckedRolesChange(value) {
            //console.log(value)
            const checkedCount = value.length
            this.checkAll = checkedCount === this.roles.length
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.roles.length
        },
        handleIsIndeterminate() {
            this.checkAll = this.checkedRoles.length == this.roles.length
            this.isIndeterminate = this.checkedRoles.length > 0 && this.checkedRoles.length < this.roles.length
        }
    }
}

</script>
